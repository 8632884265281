import './Portfolio.css';

import IMG1 from "../../images/portfolio/northCrown/photo-9.jpg";
import IMG2 from "../../images/portfolio/WelltonTowers100/photo-15.jpg";
import IMG3 from "../../images/portfolio/d1/photo-16.jpg";
import IMG4 from "../../images/portfolio/Filicity/photo-20.png";
import IMG5 from "../../images/portfolio/academy/photo-8.JPG";
import IMG6 from "../../images/portfolio/WelltonTowers33/photo-2.png";
import IMG7 from "../../images/portfolio/family/photo-5.jpg";
import IMG8 from "../../images/portfolio/spanish/photo-1.jpg";
import IMG9 from "../../images/portfolio/symbol/photo-11.jpg";
import IMG10 from "../../images/portfolio/zhk_now/photo-2.jpg";
import IMG11 from "../../images/portfolio/man_butic/photo-9.jpg";
import IMG12 from "../../images/portfolio/veresk/photo-14.jpg";
import IMG13 from "../../images/portfolio/vangarden/photo-2.jpg";
import IMG14 from "../../images/portfolio/paveletsky77/photo-3.jpg";
import IMG15 from "../../images/portfolio/paveletsky/photo-1.jpg";
import IMG16 from "../../images/portfolio/dom_chehov/photo-4.jpg";
import IMG17 from "../../images/portfolio/vangarden85/photo-1.jpg";
import IMG18 from "../../images/portfolio/vangarden67/photo-23.jpg";

import ReactBnbGallery from "react-bnb-gallery";
import React, { useState } from 'react';

import academy from "../../images/portfolio/academy/index";
import D1 from "../../images/portfolio/d1/index";
import filicity from "../../images/portfolio/Filicity/index";
import northCrown from "../../images/portfolio/northCrown/index";
import W33 from "../../images/portfolio/WelltonTowers33/index";
import W100 from "../../images/portfolio/WelltonTowers100/index";
import family from "../../images/portfolio/family/index";
import spanish from "../../images/portfolio/spanish/index";
import symbol from "../../images/portfolio/symbol/index";
import now from "../../images/portfolio/zhk_now/index";
import manbutic from "../../images/portfolio/man_butic/index";
import veresk from "../../images/portfolio/veresk/index";
import vangarden from "../../images/portfolio/vangarden/index";
import paveletsky77 from "../../images/portfolio/paveletsky77/index";
import paveletsky from "../../images/portfolio/paveletsky/index";
import dom_chehov from "../../images/portfolio/dom_chehov/index";
import vangarden85 from "../../images/portfolio/vangarden85/index";
import vangarden67 from "../../images/portfolio/vangarden67/index";

const portfolio = [
    {
        firstImage:  IMG10,
        name: "ЖК NOW",
        all: now
    },
    {
        firstImage:  IMG17,
        name: "ЖК Вангарден 85 кв.м.",
        all: vangarden85
    },
    {
        firstImage:  IMG11,
        name: "Бутик мужской одежды",
        all: manbutic
    },
    {
        firstImage:  IMG12,
        name: "ЖК Вереск",
        all: veresk
    },
    {
        firstImage:  IMG18,
        name: "ЖК Вангарден 67 кв.м.",
        all: vangarden67
    },
    {
        firstImage:  IMG13,
        name: "ЖК Вангарден",
        all: vangarden
    },
    {
        firstImage:  IMG14,
        name: "ЖК Павелецкая сити 77",
        all: paveletsky77
    },
    {
        firstImage:  IMG15,
        name: "ЖК Павелецкая сити",
        all: paveletsky
    },
    {
        firstImage:  IMG16,
        name: "Дом чехов",
        all: dom_chehov
    },
    {
        firstImage:  IMG7,
        name: "ЖК Семейный",
        all: family
    },
    {
        firstImage:  IMG8,
        name: "ЖК Испанские кварталы",
        all: spanish
    },
    {
        firstImage:  IMG9,
        name: "ЖК Символ",
        all: symbol
    },
    {
        firstImage:  IMG6,
        name: "ЖК Wellton Towers 33",
        all: W33
    },
    {
        firstImage:  IMG2,
        name: "ЖК Wellton Towers 100",
        all: W100
    },
    {
        firstImage:  IMG1,
        name: "ЖК Северная корона",
        all: northCrown
    },
    {
        firstImage:  IMG4,
        name: "ЖК Filicity",
        all: filicity
    },
    {
        firstImage:  IMG3,
        name: "ЖК D1",
        all: D1
    },
    {
        firstImage:  IMG5,
        name: "ЖК Большая академическая",
        all: academy
    },
]

function Portfolio() {
    const [isOpen, setIsOpen] = useState(false);
    const [isPhoto, setIsPhoto] = useState(academy);

    function handleWatchedChange(item) {
        setIsPhoto(item.all)
        setIsOpen(true);
    }
    const items = portfolio.map((item) =>
        <div className='portfolio_block' onClick = {() => handleWatchedChange(item)} >
            <img className='portfolio_image' src={item.firstImage} alt={item.name} />
            <p className='portfolio_title'>{ item.name }</p>
        </div>
    );

    return (
        <div className="portfolio">
            { items }
            <ReactBnbGallery
                show={isOpen}
                photos={isPhoto}
                opacity={0.9}
                showThumbnails={true}
                phrases={"Показать"}
                onClose={() => setIsOpen(false)}
            />
        </div>
    );
}

export default Portfolio;